import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { LogErroDTO } from 'src/app/models/logErroDTO';
import { LogErroService } from '../log-erro/log-erro.service';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

 

  isLoading = false;
  

  constructor( public loadingController: LoadingController
             , private alertController: AlertController 
             , private router: Router
             , private logErroService: LogErroService ) { }

  async presentLoading(message: string = ""){
    
    if(this.isLoading){
      return;
    }
    
    this.isLoading = true;
    
    return await this.loadingController.create({
      // duration: 5000,
    }).then(response => {
      
      response.cssClass = 'custom-loading'; //esta classe está localizada em theme/variables.scss 
      response.spinner = 'crescent';
      response.message = message;
      response.present().then(() => {
        
        if (!this.isLoading) {
          response.dismiss().then(() => {
            //console.log('abort presenting');
          });
        }
      
      });
    });
  }

  async dismissLoading() {
    try{
      
      this.isLoading = false;

      let erroDismiss: boolean = false;
      let cont = 0;
      while(!erroDismiss && cont<3){
        try{
            await this.loadingController.dismiss();
            //console.log('dismissed');
            cont++;
          }catch(err){
            erroDismiss = true;
            //console.log('deu erro no dismiss');
          }
      }

    }catch(err){
      return;
    }
  }


  async showAlert(title: string, message: string, buttons: any[]) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: buttons
    });

    await alert.present();
  }

  async showErrorAlert(title: string, message: string, error: any, buttons: any[]) {
    
    console.log(error);

    if(error.error.status===403){

      message = 'Sua sessão expirou.<hr>Por favor, autentique-se novamente.';

      this.showAlert( 'Atenção!'
                    , message
                    , [{ 
                        text: 'Ok',
                        handler: () => {
                          this.navigate('modulo-gc/gc-login');
                        }
                      }]);      

    }else{
      message += '<hr><b>Detalhes:</b><hr>'; 

      if(error.error?.message){
        message += error.error.message;
      }else if(error.message){
        message += error.message;
      }

      this.showAlert(title, message, buttons);
    }

  }


  async showConfirmAlert(title: string, message: string, labelButtonConfirm: string, labelButtonCancel: string) {
    return new Promise(async (resolve) => {
        const alert = await this.alertController.create({
          header: title,
          message: message,
          buttons: [{ 
                      text: labelButtonConfirm,
                      handler: () => {
                        alert.dismiss();
                        return resolve(true);
                      }
                    },
                    { 
                      text: labelButtonCancel,
                      handler: () => {
                        alert.dismiss();
                        return resolve(false);
                      }
                    }]
          });

        await alert.present().then(resp=>{return resp});
    });
    
  }


  async showConfirmAlertGetValue( title: string
                                , message: string
                                , labelButtonConfirm: string
                                , labelButtonCancel: string
                                , inputPlaceHolder: string
                                , inputValue: string
                                , cssClassName? : string):Promise<any> {
    
    return new Promise(async (resolve) => {
        const alert = await this.alertController.create({
          header: title,
          message: message,
          cssClass: cssClassName,
          inputs: [
            {
              name: 'input',
              type: 'text',
              value: inputValue,
              placeholder: inputPlaceHolder,
            }],  
          buttons: [{ 
                      text: labelButtonConfirm,
                      handler: (alertData) => {
                        alert.dismiss();
                        return resolve(alertData.input);
                      }
                    },
                    { 
                      text: labelButtonCancel,
                      handler: () => {
                        alert.dismiss();
                        return resolve(false);
                      }
                    }]
          });

         

        await alert.present().then(resp=>{return resp});
    });
    
  }

  navigate(path: string){
    this.router.navigate([path]);
    //this.functionsService.navigate(['/detalhe-curso'],{queryParams: {curso: JSON.stringify(curso)}});
  }

  navigateWithParm(path: string, parm: any){
    this.router.navigate([path],{queryParams: {parm: JSON.stringify(parm)}});
  }
  

  goToPath(path: string, newWindow: boolean = false){

    if(path){

      if( path.startsWith('/') ){        
        path = path.replace(/\(/g, '%28').replace(/\)/g, '%29');
        path = this.normalizeUrl(path);
      }
      
      if(!newWindow){
        window.location.href = path;
      }else{
        try{
          window.open(path);
        }catch(err){
          alert(err);
        }
      }
      
    }    
  }
  

  normalizeUrl(url: string): string {

    if(url.toLocaleLowerCase().endsWith('.pdf')  || 
       url.toLocaleLowerCase().endsWith('.doc')  ||
       url.toLocaleLowerCase().endsWith('.png')  ||
       url.toLocaleLowerCase().endsWith('.jpg')  ||
       url.toLocaleLowerCase().endsWith('.jpeg') ||
       url.toLocaleLowerCase().includes('modulo-gc/')){
      
      return url;  
    }
    
    // Decodifica a URL para resolver caracteres codificados como %28, %29
    url = decodeURIComponent(url);

    // Mapa de caracteres que devem ser substituídos
    const charMap = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
      'ã': 'a', 'õ': 'o', 'ñ': 'n',
      'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
      'ç': 'c', 'ü': 'u',
      'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
      'À': 'A', 'È': 'E', 'Ì': 'I', 'Ò': 'O', 'Ù': 'U',
      'Ã': 'A', 'Õ': 'O', 'Ñ': 'N',
      'Â': 'A', 'Ê': 'E', 'Î': 'I', 'Ô': 'O', 'Û': 'U',
      'Ç': 'C', 'Ü': 'U',
      '-': '_', // substituir hífens por underscore
      ' ': '_',  // substituir espaços por underscore
      '(': '_',  // eliminar abre parentese
      ')': '_',  // eliminar fecha parentese  
    };
  

    // Função para substituir cada caracter especial
    const replaceChar = (char) => charMap[char] || char;
  
    // Substituir cada caracter na URL pelo seu equivalente no mapa
    return url.replace(/[^A-Za-z0-9]/g, replaceChar);
    
  }




  sortArray(arr){
    
    arr.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

  }


  


  getDeepCopyArray(source: any[]): any[]{

    return JSON.parse(JSON.stringify(source));

  }



  getDateBrPattern(data: Date):string{
    
    data = new Date(data);
    let dia  = data.getUTCDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getUTCMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getUTCFullYear();
        
    return diaF+"/"+mesF+"/"+anoF;
  }


  getDateHourBrPattern(data: Date):string{
    data = new Date(data);
    
    let dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear(),
        hora = data.getHours().toString(),
        horaF = (hora.length == 1) ? '0'+hora : hora,
        min = data.getMinutes().toString(),
        minF = (min.length == 1) ? '0'+min : min;

    return diaF+"/"+mesF+"/"+anoF+' '+horaF+':'+minF;
  }

  getHourBrPattern(data: Date):string{
    
    let hora = data.getHours().toString(),
        horaF = (hora.length == 1) ? '0'+hora : hora,
        min = data.getMinutes().toString(),
        minF = (min.length == 1) ? '0'+min : min;

    return horaF+':'+minF;
  }


  getCurrencyValue(valor: any): string{

    if(!valor){
      valor = 0;
    }
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number.parseFloat(valor));
  }


  getFormatedNumberValue(valor: any, qtdDigitosFracionados: number): string{
    
    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: qtdDigitosFracionados, maximumFractionDigits: qtdDigitosFracionados }).format(Number.parseFloat(valor));
  }
  
 
  setCelPhoneMask(event, mask: string){

    if(mask === '(00) 00000-0000'){

      if(event.detail.inputType!=='deleteContentBackward'){

        var x = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        event.target.value = '(' +x[1] + ') '+ x[2] + '-' + x[3];
  
      }else if(event.target.value.length<15){
        this.setPhoneMask(event, '(00) 0000-0000');
      }

    }else if(mask === '00000-0000'){

      if(event.detail.inputType!=='deleteContentBackward'){

        var x = event.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,4})/);
        event.target.value = x[1] + '-' + x[2];
  
      }else if(event.target.value.length<15){
        this.setPhoneMask(event, '0000-0000');
      }

    }
    

  }


  setPhoneMask(event, mask: string){

    if(mask === '(00) 0000-0000'){

      if(event.detail.inputType!=='deleteContentBackward' || event.target.value.length>10 ){

        var x = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        event.target.value = '(' +x[1] + ') '+ x[2] + '-' + x[3];
  
      }

    }else  if(mask === '0000-0000'){

      if(event.detail.inputType!=='deleteContentBackward' || event.target.value.length>10 ){

        var x = event.target.value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})/);
        event.target.value = x[1] + '-' + x[2];
  
      }

    }
    

  }



  setCEPMask(event){

    if(event.detail.inputType!=='deleteContentBackward' ){

      var x = event.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
      event.target.value = x[1] + '-'+ x[2];

    }   

  }

  setCPFMask(event){

    if(event.detail.inputType!=='deleteContentBackward' ){

      var x = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
      
      if(x[1].length<3){
        event.target.value = x[1];
      }else if(x[1].length===3 && x[2].length<3){
        event.target.value = x[1] + '.' + x[2];  
      }else if(x[2].length===3 && x[3].length<3){
      event.target.value = x[1] + '.' + x[2] + '.' + x[3];
      }else if(x[3].length===3){
        event.target.value = x[1] + '.' + x[2] + '.' + x[3] + '-' + x[4];
      }

    }   

  }


  setDateMask(event, mask: string){

    if(mask === 'dd/MM/yyyy'){

      if(event.detail.inputType!=='deleteContentBackward' || event.target.value.length>10 ){

        var x = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
        event.target.value = x[1] + '/' + x[2] + '/' + x[3];

        if(x[1].length<2){
          event.target.value = x[1];
        }else if(x[1].length===2 && x[2].length<2){
          event.target.value = x[1] + '/' + x[2];  
        }else if(x[2].length===2){
        event.target.value = x[1] + '/' + x[2] + '/' + x[3];
        }
  
      }

    }else if(mask === 'dd/MM/yyyy HH:mm:ss'){

      if(event.detail.inputType!=='deleteContentBackward' || event.target.value.length>10 ){

        var x = event.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})(\d{0,2})(\d{0,2})(\d{0,2})/);
        event.target.value = x[1] + '/' + x[2] + '/' + x[3] + ' ' + x[4] + ':' + x[5] + ':' + x[6];
        
      }

    } 

  }

  validateDate(date: string):boolean{

    if(date.length<10){
      this.showAlert( 'Atenção!'
                    , 'Data inválida!'
                    , [{ 
                        text: 'Ok',
                        handler: () => {
                          //return false;
                        }
                      }] );
      return false;                                  
    }

    //divide a data em um array de 3 posições
    let arrData = date.split('/');
    const dia = parseInt(arrData[0]);
    const mes = parseInt(arrData[1]);
    const ano = parseInt(arrData[2]);
    
    if( (dia>31 || mes>12 || ano>new Date().getFullYear()) ||
        (dia===31 && [1,3,5,7,8,10,12].indexOf(mes)===-1) ){

      this.showAlert( 'Atenção!'
                    , 'Data inválida!'
                    , [{ 
                        text: 'Ok',
                        handler: () => {
                          //return false;
                        }
                      }] );
      return false;

    }

    return true;

  }


  validateCPF(cpf:string): boolean{

    if (typeof cpf !== "string"){ 
      return false;
    }

    cpf = cpf.replace(/[\s.-]*/igm, '');

    if ( !cpf ||
          cpf.length != 11 ||
          cpf == "00000000000" ||
          cpf == "11111111111" ||
          cpf == "22222222222" ||
          cpf == "33333333333" ||
          cpf == "44444444444" ||
          cpf == "55555555555" ||
          cpf == "66666666666" ||
          cpf == "77777777777" ||
          cpf == "88888888888" ||
          cpf == "99999999999" ) {
          return false
      }

      var soma = 0;
      var resto;
      for (var i = 1; i <= 9; i++){ 
          soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;
      if ((resto == 10) || (resto == 11)){
        resto = 0;
      }
      if (resto != parseInt(cpf.substring(9, 10)) ){
        return false;
      }
      
      soma = 0;
      for (var i = 1; i <= 10; i++){ 
          soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
      }

      resto = (soma * 10) % 11;

      if ((resto == 10) || (resto == 11)){
        resto = 0;
      }
      if (resto != parseInt(cpf.substring(10, 11) ) ){
        return false;
      }

      return true;
  
  }


  addDays(date: Date, daysToAdd: number): Date{
    date.setDate(date.getDate() + (daysToAdd) );

    return date;
  }


  getRamdonString(bits: number): string {
    
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    
    for ( var i = 0; i < bits; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getMonthNameByIndex(index:number){

    const monthNames = [ "Janeiro"
                       , "Fevereiro"
                       , "Março"
                       , "Abril"
                       , "Maio"
                       , "Junho"
                       , "Julho"
                       , "Agosto"
                       , "Setembro"
                       , "Outubro"
                       , "Novembro"
                       , "Dezembro"
                      ];

    return monthNames[index];
  }


  getDayOfWeekName(date: Date){

    const dayNames = [ "Domingo"
                     , "Segunda"
                     , "Terça"
                     , "Quarta"
                     , "Quinta"
                     , "Sexta"
                     , "Sábado"
                      ];

    
    return dayNames[ new Date(date).getDay()];
  }


  datesEqual(dateA: Date, dateB: Date): boolean{

    if(new Date(dateA).getTime() === new Date(dateB).getTime()){
      return true;
    }

    return false;
    
  }


  insertLogErro(pagina: string, operacao: string, erro: any){

    let logErro = new LogErroDTO();
    logErro.data = new Date();
    logErro.operacao = operacao;
    logErro.pagina = pagina;
    logErro.erro = JSON.stringify(erro).substring(0,15000);
    
    
    this.logErroService.insert(logErro).subscribe();

  }



  /**
   * Move um item correspondente ao início do array.
   */
  /**
 * Move um item correspondente ao início do array e destaca o elemento.
 */
  moveItemToStart(array: any[], idObjetc: number): any[] {
    const index = array.findIndex((obj) => obj.id === +idObjetc); // Localiza o índice do item
    if (index !== -1) {
      const objSelecionado = array.splice(index, 1)[0]; // Remove o item da posição original
      array.unshift(objSelecionado); // Adiciona o item no início da lista
    }

    return array; // Retorna o array atualizado
  }



  

}
