import { ArquivoDTO } from './../../models/arquivoDTO';
import { API_CONFIG } from './../../config/api.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService {

  private uploadedImage: string;

  
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  findById(id: number) : Observable<ArquivoDTO> {

    return this.http.get<ArquivoDTO>( API_CONFIG.apiURL+'/arquivosfisicos/' + id);

  }

  findBannersHome() : Observable<ArquivoDTO[]> {
    
    return this.http.get<ArquivoDTO[]>( API_CONFIG.apiURL+'/arquivosfisicos/findBannersHome');

  }

  findByPath(entidade: string, idEntidade: number, diretorio: string) : Observable<ArquivoDTO[]> {

    return this.http.get<ArquivoDTO[]>( API_CONFIG.apiURL+'/arquivosfisicos/findByPath?entidade=' + entidade + '&idEntidade=' + idEntidade + '&diretorio=' + diretorio);

  }

  findEditalByCurso(idCurso: number) : Observable<ArquivoDTO[]> {

    return this.http.get<ArquivoDTO[]>( API_CONFIG.apiURL+'/arquivosfisicos/findEditalByCurso?idCurso=' + idCurso );

  }

  delete(id: number) : Observable<void> {

    return this.http.delete<void>( `${API_CONFIG.apiURL}${'/arquivosfisicos/'}${id}`);

  }

  deleteFromFileSystem(entidade: string, diretorio: string, idEntidade: number, fileName: string) : Observable<void> {

    return this.http.delete<void>( `${API_CONFIG.apiURL}${'/arquivosfisicos/deleteFromFileSystem/'}${entidade}${'/'}${diretorio}${'/'}${idEntidade}${'/'}${fileName}`);

  }

  deleteFromFileSystem2(diretorio: string, fileName: string) : Observable<void> {

    return this.http.delete<void>( `${API_CONFIG.apiURL}${'/arquivosfisicos/deleteFromFileSystem2/'}${diretorio}${'/'}${fileName}`);
    
  }

  update(arquivoDTO: ArquivoDTO) : Observable<any> {
    
    return this.http.put<any>( API_CONFIG.apiURL+'/arquivosfisicos/' + arquivoDTO.id
                             , arquivoDTO
                             , { observe: 'response' });

  }

  upload( arquivo: File
        , nomeEntidadeAssociada:string
        , idEntidadeAssociada: number
        , nomeDiretorioDestino: string
        , tipoArquivo: string
        , nomeArquivo: string = ""
        , ordemExibicaoArquivo?: number ): Observable<any>{

    const formData = new FormData();
    formData.append('arquivo', arquivo);
    formData.append('nomeEntidadeAssociada', nomeEntidadeAssociada);
    formData.append('idEntidadeAssociada', idEntidadeAssociada?.toString());
    formData.append('nomeDiretorioDestino', nomeDiretorioDestino);
    formData.append('tipoArquivo', tipoArquivo);
    formData.append('nomeArquivo', nomeArquivo);
    formData.append('ordemExibicaoArquivo', ordemExibicaoArquivo?.toString());

    return this.http.post<any>( API_CONFIG.apiURL+'/arquivosfisicos/upload'
                              , formData
                              , { observe: 'response' }); //.pipe(timeout(100000))
   
  }


  copyAndPaste( pathOrigem:string
              , nomeArquivoOrigem: string
              , nomeEntidadeDestino: string
              , idEntidadeDestino: number
              , tipoArquivo: string
              , nomeDiretorioDestino: string 
              , nomeArquivoDestino: string ): Observable<any>{

      
      //para usar formData é necessário remover o content-type = application:json do header no httpconfig.interceptor.ts     
      let formData = new FormData();
      formData.append('pathOrigem', pathOrigem);
      formData.append('nomeArquivoOrigem', nomeArquivoOrigem);
      formData.append('nomeEntidadeDestino', nomeEntidadeDestino);
      formData.append('idEntidadeDestino', idEntidadeDestino.toString());
      formData.append('nomeDiretorioDestino', nomeDiretorioDestino);
      formData.append('tipoArquivo', tipoArquivo);
      formData.append('nomeArquivoDestino', nomeArquivoDestino);

      return this.http.post<any>( API_CONFIG.apiURL+'/arquivosfisicos/copyAndPaste?'
                                , formData
                                , { observe: 'response' }); //.pipe(timeout(100000))

}


  insert(arquivoDTO: ArquivoDTO) : Observable<any> {
    
    return this.http.post<any>( API_CONFIG.apiURL+'/arquivosfisicos'
                              , arquivoDTO
                              , { observe: 'response' }); //.pipe(timeout(100000))

  }

  getPDFBase64(file, callback) {

    new Promise((resolve, reject) => {

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        return callback((reader.result));
      }
      reader.onerror = error => {
        return callback((error));
      }
    
    });

  }

  getBase64File(fileLoader: HTMLInputElement){

    let that = this;
    
    return new Promise(function(resolve, reject) {

      
      fileLoader.onchange = function () {

          var file = fileLoader.files[0];
          var reader = new FileReader();

          reader.addEventListener("load", function () {

            if(fileLoader.files[0].type === 'application/pdf'){ //se for arquivo pdf

              that.getPDFBase64(fileLoader.files[0], function(data){
                that.uploadedImage = data;
                resolve(that.uploadedImage);
              });

            }else if(fileLoader.files[0].type === 'image/jpeg' || fileLoader.files[0].type === 'image/png'){


              that.getOrientation(fileLoader.files[0], function (orientation) {
                  
                  if (orientation > 1) {
                      that.resetOrientation(reader.result, orientation, function (resetBase64Image) {
                        that.uploadedImage = resetBase64Image;
                        resolve(that.uploadedImage);
                      });
                  } else {
                      that.uploadedImage = reader.result.toString();
                      resolve(that.uploadedImage);
                  }

              });
            }
          }, false);


          if (file) {        
            reader.readAsDataURL(file);
          }
      }

      fileLoader.click();

    });

  }


  private getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function (e:any) {

      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) != 0xFFD8) return callback(-2);
      var length = view.byteLength, offset = 2;
      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;
        if (marker == 0xFFE1) {
          if (view.getUint32(offset += 2, false) != 0x45786966) return callback(-1);
          var little = view.getUint16(offset += 6, false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;
          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + (i * 12), little) == 0x0112)
              return callback(view.getUint16(offset + (i * 12) + 8, little));
        }
        else if ((marker & 0xFF00) != 0xFF00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  }


  private resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image();

    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }


  getFilePublicURL(fullPath: string, fileName: string): string{

    //trocar a parte do diretório raiz pela url do diretório virtual
    const pathVirtual = fullPath?.toLocaleLowerCase().replace( API_CONFIG.iisVirtualDirectoryPath.toLocaleLowerCase()
                                                             , API_CONFIG.iisVirtualDirectoryURL.toLocaleLowerCase());

    if(fileName){
      return pathVirtual + "/" + fileName;
    }                                                         
    
    return pathVirtual;
  }


  base64ToFile(dataurl: string, filename: string): File{
 
    //Usage example:
    //var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
    //console.log(file);
  

      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }
  

  convertToPDFA(pathOrigem: string, nomesArquivosOrigem: string[]): Observable<string[]>{

    return this.http.get<string[]>( API_CONFIG.apiURL+'/arquivosfisicos/convertToPDFA?pathOrigem=' + pathOrigem + '&nomesArquivosOrigem='+nomesArquivosOrigem);

  }


  getSafeViewerPath(arquivo: ArquivoDTO): SafeResourceUrl{

    if(
        arquivo.nome.toLowerCase().endsWith('xls') || 
        arquivo.nome.toLowerCase().endsWith('xlsx') ||
        arquivo.nome.toLowerCase().endsWith('doc') || 
        arquivo.nome.toLowerCase().endsWith('docx') ||
        arquivo.nome.toLowerCase().endsWith('ppt') || 
        arquivo.nome.toLowerCase().endsWith('pptx')
      ){
      
      const path = this.getFilePublicURL('https://view.officeapps.live.com/op/view.aspx?src=' + arquivo.path, arquivo.nome);
      return this.sanitizer.bypassSecurityTrustResourceUrl(path);
    
    }else{
      
      // Adiciona um parâmetro único para evitar cache
      const timestamp = new Date().getTime();
      const path = this.getFilePublicURL(arquivo.path, arquivo.nome) + '?nocache=' + timestamp;
      return this.sanitizer.bypassSecurityTrustResourceUrl(path);
    
    }
  }
  
}
