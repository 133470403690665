export const ENTIDADE = {
    
    NOTICIA: "NOTICIA",
    HOME: "HOME",
    CURSO: "CURSO",
    FORMACAO: "FORMACAO",
    CANDIDATO: "CANDIDATO",
    SALA: "SALA",
    AGRUPAMENTO: "AGRUPAMENTO",
    GRUPO_FUNCIONALIDADE: "GRUPO_FUNCIONALIDADE",
    PARAMETRO_ANALISE_DOCUMENTO: "PARAMETRO_ANALISE_DOCUMENTO",
    INSCRICAO: "INSCRICAO",
    SITE: "SITE",
    PAGINA: "PAGINA",
    VESTIBULAR: "VESTIBULAR",
    EMAIL: "EMAIL",
    CONFIGURACAO_TEMPLATE_EMAIL: "CONFIGURACAO_TEMPLATE_EMAIL", 
    PAGAMENTO: "PAGAMENTO",
    REPOSITORIO: "REPOSITORIO",
}