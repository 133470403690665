export const DIRETORIO = {
    
    BANNER: "BANNER",
    BANNER_REDUZIDO: "BANNER_REDUZIDO",
    GALERIA_FOTOS: "GALERIA_FOTOS",
    GALERIA_BANNERS: "GALERIA_BANNERS",
    GALERIA_DOWNLOADS: "GALERIA_DOWNLOADS",
    POPUP: "POPUP",
    GRADES_CURRICULARES: "GRADES_CURRICULARES",
    GALERIA_DEPOIMENTOS: "GALERIA_DEPOIMENTOS",
    AVATAR: "AVATAR",
    TERMO_RESPONSABILIDADE: "TERMO_RESPONSABILIDADE",
    DOCUMENTACAO: "DOCUMENTACAO",
    PUBLIC: "PUBLIC",
    LOGO: "LOGO",
    TEMP: "TEMP",
    TEXTO_FORMATADO: "TEXTO_FORMATADO",
    EDITAL: "EDITAL",
    REPOSITORIO_ARQUIVOS: "REPOSITORIO_ARQUIVOS",
}